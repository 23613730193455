<template>
    <div class="w-full">
        <div class="flex w-full">
            <div class="py-1 w-full">
                <div class="grid grid-cols-12 w-full">
                    <div class="col-span-12 sm:col-span-6 lg:col-span-4">
                        <ItemText title="Noliktava" :text="item.warehouse.name" />
                    </div>
                    
                    <div class="col-span-12 sm:col-span-6 lg:col-span-4">
                        <ItemText title="Piejamais atlikums" :text="item.quantity" />
                    </div>
                </div>
            </div>

            <div class="relative flex">
                <template v-if="loading">
                    <div class="pt-2.5">
                        <Loading />
                    </div>
                </template>

                <template v-if="!loading && category && category.use_unique_codes">
                    <Button :icon="showDetails ? 'chevron-up' : 'chevron-down'" @click="showData" />
                </template>

            </div>
        </div>

        <template v-if="showDetails && !loading">
            <div class="w-full px-3 py-2 bg-gray-200 dark:bg-gray-800 rounded-lg">
                <template v-for="uniqueItem in uniqueItems" :key="uniqueItem.id">
                    <router-link :to="`/stock/procurements/${uniqueItem.procurement.id}`" class="w-full grid grid-cols-12 hover:bg-gray-100 dark:hover:bg-gray-750 px-2 rounded-lg">
                        <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                            <ItemText :title="uniqueItem.procurement.supplier" :text="uniqueItem.procurement.supplier_uuid" />
                        </div>
                        
                        <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                            <ItemText title="Iepirkuma cena" :text="uniqueItem.price" />
                        </div>

                        <div class="col-span-12 sm:col-span-6 lg:col-span-3" @click="textClick">
                            <ItemText title="Kods">
                                <template v-slot:content>
                                    <router-link :to="`/catalog_item_units/${uniqueItem.id}`" class="mt-2 flex items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0 relative z-10">
                                        {{uniqueItem.number}}
                                        <Button icon="copy" tooltip="Kopēt kodu" @click="copyText(uniqueItem.number)" className="px-1 py-1 mt-3 ml-2 -mt-2" />
                                    </router-link>
                                </template>
                            </ItemText>

                        </div>

                        <template v-if="uniqueItem.order">
                            <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                                <ItemText title="Pasūtījums" :text="uniqueItem.order.uuid" />
                            </div>
                        </template>
                    </router-link>
                </template>
            </div>
        </template>

    </div>
</template>

<script>
import ItemText from "@/components/Components/ItemText"
import Loading from "@/components/Components/Loading"

export default {
    name: "SingleStockItemDetails",
    components: {
        ItemText,
        Loading,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        category: {
            type: Object,
        }        
    },
    data() {
            return {
                showDetails: false,
                uniqueItems: null,
                loading: false,
            }
    },
    methods: {
        showData() {
            this.showDetails = !this.showDetails

            if(!this.uniqueItems) {
                this.getData()
            }
        },
        getData() {
            this.loading = true
            this.$store.dispatch("getItemUniqueItems", this.item.id)
                .then(response => {
                    this.uniqueItems = response.data
                })
                .catch(error => {})
                .finally(() => {
                    this.loading = false
                })
        },
        copyText(text) {
            var dummy = document.createElement("textarea");
            // to avoid breaking orgain page when copying more words
            // cant copy when adding below this code
            // dummy.style.display = 'none'
            document.body.appendChild(dummy);
            //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
        },
        textClick(e) {
            e.preventDefault();
        }
    }
}
</script>